<template>
  <div class="cityAuditionContainers">
    <!-- PC电脑端 -->
    <div class="PCBox">
      <div class="cityAuditionTop">
        <el-image :src="cityAuditionImg1" fit="cover" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
      </div>
      <div class="cityAuditionBox">
        <el-image :src="cityAuditionImg2" fit="cover" :poster="posterImg" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
        <video class="videoUrlBox" ref="myVideo" :src="videoUrl" controls="controls">您的浏览器不支持视频播放</video>
      </div>
      <div class="cityAuditionBox2">
        <el-image :src="cityAuditionImg3" fit="cover" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
      </div>
    </div>

    <!-- H5手机端 -->
    <div class="H5Box">
      <div class="mobileTop">
        <el-image :src="mobileImg1" fit="cover" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
      </div>
      <div class="videoBox">
        <el-image class="videoBackImg" :src="videoBack" fit="cover"></el-image>
        <el-image class="videoBackImg2" :src="mobileImg2" fit="cover"></el-image>
        <video class="videoStyle" :poster="posterImg" ref="myVideo" :src="videoUrl" controls="controls"></video>
      </div>
      <div class="mobileTop">
        <el-image :src="mobileImg3" fit="cover" style="width: 100%;height: 100%;cursor: pointer;"></el-image>
      </div>
    </div>
      




  </div>
</template>

<script>
import { request } from '@/utils/request';
export default {
  name: 'cityAudition',
  components: {},
  data() {
    return {
      cityAuditionImg1: "http://djs.thinkerwork.com/20230426182323295_cityAuditionImg1.png",
      cityAuditionImg2: "http://djs.thinkerwork.com/20230426182519437_cityAuditionImg2.png",
      cityAuditionImg3: "http://djs.thinkerwork.com/20230426182336687_cityAuditionImg3.png",
      videoUrl: "https://djs.thinkerwork.com/%E8%A7%86%E9%A2%91/%E5%BF%83%E5%AE%A2%20%E9%A2%84%E8%A7%81%E7%8B%AC%E8%A7%92%E5%85%BD.mp4",
      mobileImg1: "http://file.thinkervc.cn/sql69eazztm040xhp8uefr2x8bdx0ebtx86melxpkhw0li09jnp4pw0c90ei81qldccxq4acxv644cz8.png",
      videoBack: "http://file.thinkervc.cn/p7x1oappsylm2848rwbysoyjgkumoca5cgx6oriidhxoufdx8797y8kon3w7jhvpwq8r35cw4io0ec8b.png",
      mobileImg2: "http://file.thinkervc.cn/h7hvwz1kudwf7qrlvasbl33023wqherb8rcwy1bz91tpbv7vcn7uhk8ekl5p1ghx4aabi8k281dlhgxv.png",
      mobileImg3: "https://file.thinkervc.cn/7453dc7947f34fe0bb23388e1237f117.png",
      posterImg: "http://file.thinkervc.cn/is74ycgicum5c9d43c1rea4ybdwu911sw9hqxmooz2yl7tu4er8xkz14d79ufnhwqu61lophm8419d0w.jpg"
    };
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
/* 去除默认样式 */
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: #f08519;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
.verticals{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.transition{transition: .5s;-moz-transition: .5s;-webkit-transition: .5s;-o-transition: .5s;-ms-transition: .5s;}
/* 两端对齐，项目之间的间隔都相等 */
.flex_between {
	display: flex;
	justify-content: space-between;
}
.centerAlign{
	display: flex;
	align-items: center;
}
.line4{
	overflow:hidden;
	text-overflow:ellipsis;
	white-space: normal;
	display:-webkit-box;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:4;
}
.cityAuditionContainers{
  width: 100%;
  min-height: 100vh;
  background: #0B004C;
}
.PCBox{
  display: block;
  width: 100%;
  min-height: 100vh;
  background: #0B004C;
}
.cityAuditionTop{
  width: 100%;
  // height: 800px;
}
.cityAuditionBox{
  position: relative;
  width: 100%;
  height: 720px;
}
.cityAuditionBox2{
  width: 100%;
  // height: 5994px;
}
.videoUrlBox{
  position: absolute;
  width: 570px;
  height: 340px;
  z-index: 6;
  right: 20%;
  top: 260px;
}

.H5Box{
  display: none;
  width: 100%;
  height: auto;
  background: #0B004C;
}
.mobileTop{
  width: 100%;
  padding: 0;
}
.videoBox{
  position: relative;
  width: 100%;
  height: 539px;
}
.videoBackImg{
  position: absolute;
  width: 100%;
  height: 538px;
  z-index: -1;
}
.videoBackImg2{
  position: absolute;
  top: 52px;
  left: 15px;
  right: 15px;
  bottom: -5px;
  width: 92%;
  z-index: 0;
}
.videoStyle{
  position: absolute;
  top: 122px;
  left: 30px;
  width: 84%;
  height: 195px;
}
@media screen and (max-width: 1800px) {
  .videoUrlBox{
    width: 550px;
    height: 330px;
    right: 16%;
  }
}

@media screen and (max-width: 1600px) {
  .videoUrlBox{
    width: 550px;
    height: 330px;
    right: 15%;
  }
}

@media screen and (max-width: 1400px) {
  .videoUrlBox{
    width: 550px;
    height: 330px;
    right: 8%;
  }
}

@media screen and (max-width: 1200px) {
  .videoUrlBox{
    width: 530px;
    height: 320px;
    right: 3%;
  }
}
@media screen and (max-width: 900px) {
  .PCBox{
    display: none;
  }
  .H5Box{
    display: block;
  }
}
</style>
